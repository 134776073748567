@import "~@clientio/rappid/rappid.css";

.canvas {
  height: calc(100vh - 136px);
  width: 100%;
  display: block;
  position: relative;
  border: 1px solid lightgray;
}

[dir="rtl"] .canvas {
  margin: 0 50px 0 0;
}

[dir="rtl"] .joint-paper {
  direction: rtl;
}

.flexArea {
  display: flex;
}

.myaction {
  background-image: url("../../Assets/icons/pencilIcon.svg");
}

.link-tools .tool-options {
  display: none;
}

.btn-save {
  margin: 0 50%;
  transform: translateX(-50%);
  font-weight: bold;
  border-radius: 5px;
  color: white;
  background-color: rgb(75, 74, 103);
  padding: 10px;
  border-width: 0;
  cursor: pointer;
}

.joint-popup.joint-theme-modern {
  max-width: 600px;
  padding: 20px;
  min-width: 300px;
}

.add-extra-info {
  font-weight: bold;
}

.info-field {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
}

.input-info {
  padding: 8px;
  flex-grow: 1;
}

.add-info {
  margin-left: 10px;
  border-radius: 5px;
  color: white;
  background-color: rgb(75, 74, 103);
  padding: 10px;
  border: none;
  cursor: pointer;
}

.infos label.disabled {
  margin: 5px 0 5px;
  color: rgba(0, 0, 0, 0.6);
}

.infos input {
  margin-right: 10px;
}

.note {
  color: #4e4e4e;
}

.joint-dialog.joint-flash-message.joint-theme-modern .body {
  width: 92%;
  margin-left: 8%;
  position: relative;
}

.joint-dialog.joint-flash-message.joint-theme-modern[data-type="warning"]
  .body:before {
  position: absolute;
  left: -17px;
  top: 50%;
  height: 20px;
  width: 20px;
  transform: translateY(-50%);
  color: #ff9800;
  content: "";
  background: url("../../Assets/icons/warning.svg") no-repeat;
  background-size: contain;
}

.extra-info-popup .rte-modern.rte-desktop.rte-toolbar-default {
  max-width: 100%;
  min-width: unset;
  margin: 15px 0;
}

.rte_command_toggleborder,
.rte_command_cut,
.rte_command_copy,
.rte_command_paste,
.rte_command_delete,
.rte_command_find,
.rte_command_insertemoji,
.rte_command_removeformat,
.rte_command_inserttable,
.rte_command_fullscreenenter,
.rte_command_selectall,
.rte_command_insertdocument,
.rte_command_togglemore,
.rte_command_lineheight,
.rte_command_inlinestyle {
  display: none;
}

.extra-info-popup rte-line-spliter {
  margin: 10px 10px !important;
}

.extra-info-popup rte-toolbar-group {
  justify-content: flex-start !important;
  display: flex;
  flex: unset !important;
  flex-wrap: wrap;
}

.extra-info-popup {
  font-family: Arial, sans-serif;
}

.loadingcenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 990px) {
  .flexArea {
    display: none;
  }
}
