.qad-content {
  margin-top: 20%;
  margin-bottom: 20%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
}
.qad-question-header {
  text-align: center;
  color: #4b4a67;
}
.qad-options {
  text-align: center;
  margin: 10px;
}
.qad-option.qad-button {
  border: 1px solid #6a6c8b;
  color: #6a6c8b;
  background-color: transparent;
  border-radius: 15px;
  min-height: 30px;
  padding: 3px 15px;
  margin-top: 20px;
  margin-right: 14px;
  font-size: 10pt;
  font-family: Helvetica Neue;
  cursor: pointer;
  outline: none;
}
.qad-button:hover {
  background-color: #6a6c8b;
  color: white;
}

.qad-close {
  float: right;
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
}

.preview {
  display: none;
}

.preview .background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.5;
}

@media screen and (min-width: 800px) {
  .preview .qad-content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    max-width: 600px;
    padding: 0 0 20px;
    border: 1px solid #eeeeee;
  }
}

.preview .qad-detail {
  padding: 20px;
}

.preview .qad-label {
  font-weight: bold;
}

.preview .qad-note {
  color: grey;
}

.qad-checkboxes-detail {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.qad-checkboxes-detail .selection-label {
  display: block;
  margin: 5px 0 5px;
  cursor: pointer;
}

.qad-checkboxes-detail .checkbox-label {
  display: block;
  margin: 5px 0 5px;
  cursor: pointer;
}

.qad-checkboxes-detail .checkbox-label span {
  margin-left: 10px;
}

.qad-listItems-detail {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.qad-listItems-detail .listItems-label {
  display: block;
  margin: 5px 0 5px;
  cursor: pointer;
}

.qad-listItems-detail .listItem-label {
  display: block;
  margin: 5px 0 5px;
  cursor: pointer;
}

.qad-listItems-detail .listItem-label span {
  margin-left: 10px;
}

.qad-textBox-detail {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.qad-textBox-detail .textBox-label {
  display: block;
  margin: 5px 0 5px;
  cursor: pointer;
}

.qad-textBox-detail .textBox-label h3 {
  margin-left: 10px;
}

.qad-guidanceText-detail {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.qad-guidanceText-detail .guidanceText-label {
  display: block;
  margin: 5px 0 5px;
  cursor: pointer;
}

.qad-guidanceText-detail .guidanceText-label h3 {
  margin-left: 10px;
}

.qad-text-detail {
  line-height: 1;
}

.qad-Timer-detail {
  background-color: black;
  color: white;
  padding: "8px";
}
.qad-Timer-detail h3 {
}
.qad-Timer-detai .timer-activity {
}
.timer-activity {
  background-color: brown;
}

.qad-detail {
  /* Add your styles for the detail container here */
}

.qad-listItems-detail {
  /* Add your styles for the list items container here */
}

.listItem-container {
  /* Add your styles for each list item container here */
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.listItems-label {
  /* Add your styles for the list item label here */
  margin-right: 10px;
}

.listItem-input {
  /* Add your styles for the editable input here */

  font-size: 14px;
}

.delete-button {
  /* Add your styles for the delete button here */
  margin-left: 10px;
}

/* Add any additional styles you need for the list items */
