.mycentercontainer {
  color: #8192b8;
  position: relative;
  left: 0%;
  top: 60px;
  padding: 0 10px 20px 10px;
  flex: 0 1 0;
  min-width: 40vw;
}

.mycentercontainer > span {
  float: right;
}

.mytable {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

td {
  padding: 10px;
  border-bottom: 1px solid #8192b8;
}

th {
  text-align: left;
}

.twocolumnflex {
  display: flex;
  flex-direction: row;
}

.tworowflex {
  display: flex;
  flex-direction: column;
}

.limitheight {
  max-height: 40vh;
  overflow: auto;
}

.highlighted {
  background-color: rgba(255, 144, 0, 0.5);
}

.mytable tr {
  cursor: pointer;
}
