.preslist {
  color: #8192b8;
}

.centercontainer {
  position: absolute;
  left: 50%;
  top: 120px;
  transform: translate(-50%, 0%);
  padding: 0 10px 20px 10px;
  width: 50%;
}

.centercontainer > span {
  float: right;
}

[dir="rtl"] .centercontainer > span {
  float: left;
}

.prescriptionlist {
  list-style-type: none;
  padding: 0;
}

.prescriptionlist li {
  margin-bottom: 10px;
  background-color: #8192b8;
  color: white;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  text-align: center;
}

.prescriptionlist li.disabledbutton {
  margin-bottom: 10px;
  background-color: lightgray;
  color: white;
  border-radius: 5px;
  padding: 5px;
  cursor: default;
  text-align: center;
}

.disabledicon {
  color: lightgray;
}

.disabledicon svg {
  cursor: default;
}

.prescriptionbox {
  margin-left: 20px;
  position: relative;
  width: 200px;
  color: #8192b8;
}

svg {
  cursor: pointer;
}

td {
  padding: 10px;
  border-bottom: 1px solid #8192b8;
}

th {
  text-align: left;
}

[dir="rtl"] th {
  text-align: right;
}

[dir="rtl"] td {
  text-align: right;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

.react-tabs__tab--selected {
  background-color: #8192b8;
  color: white;
  border-color: black !important;
}

.react-tabs__tab--disabled {
  color: lightgray;
}

.react-tabs__tab {
  border: 1px solid #eeeeee;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.user_dropdown {
  position: fixed;
  background-color: white;
  margin-right: 20px;
  border: 1px solid gray;
  border-radius: 5px;
}

.user_dropdown p {
  margin: 5px 10px 5px 10px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}

.user_dropdown p:hover {
  background-color: lightgray;
}

.online {
  color: lightgreen;
}

.offline {
  color: lightgray;
}

.busy {
  color: red;
}

.smallicon {
  font-size: 0.7em;
  position: relative;
  top: -6px;
}

.equallySpaced {
  display: flex;
  position: relative;
  top: 1px;
}

.equallySpaced * {
  flex: 1;
  text-align: center;
}
