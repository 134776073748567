.SideMenu {
  width: 331px;
  height: 100%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.SideMenu ul {
  list-style-type: none;
  width: 60%;
  margin: auto;
}
.SideMenu .li {
  color: #212427;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.active {
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  padding-left: 24px;
  color: #175384;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
