.chatOnlineFriend {
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
}

.chatOnlineImgContainer {
  position: relative;
  margin-right: 10px;
}

.chatOnlineImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid white;
}

.chatOnlineBadge {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: 2px;
  right: 2px;
}

@media screen and (max-width: 768px) {
  .chatOnlineName {
    display: none;
  }
}
